<template>
  <el-card class="password-manage">
    <div slot="header" class="clearfix"><span>{{$t('updatePassword')}}</span></div>
    <el-form :model="passwordForm" :rules="rules" ref="passwordForm">
      <el-form-item :label="$t('oldPassword')" prop="oldPassword">
        <el-input v-model="passwordForm.oldPassword" size="medium" show-password></el-input>
      </el-form-item>
      <el-form-item :label="$t('newPassword')" prop="newPassword">
        <el-input v-model="passwordForm.newPassword" size="medium" show-password></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="medium" type="primary" @click="onSubmit('passwordForm')">{{$t('submit')}}</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>

import verification from '@/assets/uilt/verification'
import org from '@/api/org'
import customerBusiness from '@/api/customerBusiness'
import storage from '@/assets/uilt/storage';

export default {
  data () {
    return {
      passwordForm: {},
      loginType: null
    }
  },
  computed: {
    rules () {
      let verify = new verification(this);
      return {
        oldPassword: [{ required: true, validator: verify.password, trigger: 'blur' }],
        newPassword: [{ required: true, validator: verify.password, trigger: 'blur' }],
        affirmPassword: [{ required: true, validator: verify.password, trigger: 'blur' }],
      }
    }
  },
  methods: {
    onSubmit (refName) {
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        if (this.loginType.name == 'merchantLogin') {
          org.changePassword({
            param: this[refName],
            success: res => {
              if (res.code == 200)
                this.$message.success(this.$t('password changed successfully'))
              else
                this.$message.warning(this.$t('password changed failed') + ',' + this.$t(res.msg))
            }
          })
        }
        else if (this.loginType.name == 'customerLogin') {
          customerBusiness.changePassword({
            param: this[refName],
            success: res => {
              if (res.code == 200)
                this.$message.success(this.$t('password changed successfully'))
              else
                this.$message.warning(this.$t('password changed failed') + ',' + this.$t(res.msg))
            }
          })
        }
      })
    }
  },
  mounted () {
    let loginName = storage.local.get('loginName');
    this.$set(this, 'loginType', loginName)
    if (!loginName)
      this.$router.push({ name: 'merchantLogin' })
  }
}
</script>

<style lang="less" scoped>
.password-manage {
  position: absolute;
  width: 400px;
  top: calc((100% - 460px) / 3);
  left: calc((100% - 400px) / 2);
  .clearfix {
    font-size: 18px;
  }
}
</style>