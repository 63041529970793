let il8n = undefined;
let verification = function (that) {
  il8n = that;
  /**正常的Number类型 */
  this.number = function (rule, value, callback) {
    let type = Object.prototype.toString.call(value);
    if (type == "[object Number]") value = value.toString();

    if (!Number(value) && value != 0) {
      callback(new Error(il8n.$t('Please enter the number (e.g., 123, 0.123)')));
    } else if (value && value.split('.')[1] && value.split('.')[1].length > 3) {
      callback(new Error(il8n.$t('Three decimal places at most')));
    }
    else {
      callback();
    }
  };
  /**必须两位有效数字 */
  this.numberCode2 = function (rule, value, callback) {
    let type = Object.prototype.toString.call(value);
    if (type == "[object Number]") value = value.toString();

    if (value === '' || !value || value.length != 2) {
      callback(new Error('The length must be equal to 2 significant digits'));
    } else if (!Number(value)) {
      callback(new Error('Must be a pure number'));
    } else if (Number(value) < 10 || Number(value) > 99 || value.indexOf('.') != -1) {
      callback(new Error('Please enter Arabic numerals between 10 and 99, not including decimals'));
    }
    else {
      callback();
    }
  };
  /**必须3位数字，可以00、0开头 */
  this.numberCode3 = function (rule, value, callback) {
    let type = Object.prototype.toString.call(value);
    if (type == "[object Number]") value = value.toString();

    if (value === '' || !value || value.length != 3) {
      callback(new Error('The length must be equal to 3 significant digits'));
    } else if (value.indexOf('.') != -1) {
      callback(new Error('You cannot include decimals'));
    } else if (!Number(value)) {
      callback(new Error('Must be a pure number (can start with 0)'));
    }
    else {
      callback();
    }
  };
  /**密码可为空,不为空时长度因该在6~18位之间*/
  this.password = function (rule, value, callback) {
    if (!value || value.length < 6) callback(new Error(il8n.$t('notLoginPassword')));
    else callback()
  };

  this.radio = function (rule, value, callback) {
    if (!value || value == 0) callback(new Error('Please select audit status'));
    else callback()
  }
}

export default verification;